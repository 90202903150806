/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import InfoLabel from '../../../components/info-label/InfoLabel';
import images from '../../../res/images';
import './style.css';


const DashboardContent = ({ user, history }) => {
  const items = {
    needy: [
      {
        title: 'Ricerca professionisti',
        icon: images.professional,
        onClick: () => history.push('/'),
      },
      {
        title: 'Agenda',
        icon: images.calendar,
        onClick: () => history.push('/dashboard/calendario'),
      },
      {
        title: 'Richieste',
        icon: images.request,
        description: 'Visualizza la lista di tutte le richieste effettuate',
        onClick: () => history.push('/dashboard/richieste'),
      },
      {
        title: 'Prestazioni',
        description: 'Visualizza la lista di tutte le prestazioni confermate',
        onClick: () => history.push('/dashboard/prestazioni'),
      } //,
      // {
      //   title: 'Metodi di pagamento',
      //   description: 'Gestisci i tuoi metodi di pagamento',
      //   onClick: () => history.push('/dashboard/preferenze/metodi-pagamento'),
      // },
    ],

    carer: [
      {
        title: 'Ricerca professionisti',
        icon: images.professional,
        onClick: () => history.push('/'),
      },
      {
        title: 'Agenda',
        icon: images.calendar,
        onClick: () => history.push('/dashboard/calendario'),
      },
      {
        title: 'I miei anziani',
        icon: images.my_needy,
        description: 'Gestisci le utenze per cui richiedi i trattamenti ed i servizi',
        onClick: () => history.push('/dashboard/preferenze/anziani'),
      },
      {
        title: 'Richieste',
        icon: images.request,
        description: 'Visualizza la lista di tutte le richieste effettuate',
        onClick: () => history.push('/dashboard/richieste'),
      },
      {
        title: 'Prestazioni',
        description: 'Visualizza la lista di tutte le prestazioni confermate',
        onClick: () => history.push('/dashboard/prestazioni'),
      }//,
      // {
      //   title: 'Metodi di pagamento',
      //   description: 'Gestisci i tuoi metodi di pagamento',
      //   onClick: () => history.push('/dashboard/preferenze/metodi-pagamento'),
      // },
    ],

    supplier: [
      {
        title: 'La mia scheda da fornitore',
        icon: images.professional,
        onClick: () => history.push('/dashboard/fornitore'),
      },
      {
        title: 'Agenda',
        icon: images.calendar,
        onClick: () => history.push('/dashboard/calendario'),
      },
      {
        title: 'Richieste',
        icon: images.request,
        description: 'Visualizza la lista di tutte le richieste effettuate',
        onClick: () => history.push('/dashboard/richieste'),
      },
      {
        title: 'Prestazioni',
        description: 'Visualizza la lista di tutte le prestazioni confermate',
        onClick: () => history.push('/dashboard/prestazioni'),
      },
    ],
  };


  return user && user.data ? (
    <div className="reservation-requests-wrapper">
      

      {user.data.type === 'supplier' && user.data.info.doc_front_path === null && (
      <InfoLabel text="Documento d'identità non inviato. Non comparirai tra i fornitori disponibili se non ne fornirai uno." link="/dashboard/fornitore/documenti" />
      )}


      <Grid container>
        {items[user.data.type] && items[user.data.type].map((item) => (
          <Grid item={true} xs={12} sm={6} md={4}>
            <BigButton
              title={item.title}
              icon={item.icon}
              description={item.description}
              onClick={item.onClick}
            />
          </Grid>
        ))}


      </Grid>
    
    </div>
  ) : null;
};


export const BigButton = ({
  title, description, icon, onClick,
}) => (
  <div onClick={onClick} className="big-button" style={{ backgroundImage: `url(${icon})` }}>
    <span className="big-button-title">{title}</span>
    <span className="big-button-description">{description}</span>
    <ArrowForwardIcon style={{
      color: 'white', fontSize: 40, position: 'absolute', bottom: 10, right: 10,
    }}
    />
  </div>
);
export default withRouter(DashboardContent);
