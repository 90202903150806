/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Formik } from 'formik';

import Swal from 'sweetalert2';
import { getMediaUrl } from '../../../../libs/utils';

import Loader from '../../../../components/loader';
import CustomInput from '../../../../components/custom-input';

import './styles.css';
import ProfileMediaInput from '../../../../components/profile-media-input';
import colors from '../../../../res/colors';
import { getSupplierValidationSchema, getUserValidationSchema } from '../../../registration/registration-form/validations';
import ImageUploader from 'react-images-upload';


const AccountSettings = ({
  user, genders, updateUserData, updateUserProfilePhoto, fetchGenders,
}) => {
  const formRef = React.useRef(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [editType, setEditType] = React.useState();
  const [editKey, setEditKey] = React.useState();
  const [editValue, setEditValue] = React.useState();
  const [editLabel, setEditLabel] = React.useState();
  const [editErrors, setEditErrors] = React.useState();

  React.useEffect(() => {
    fetchGenders();
  }, []);

  const editFormSubmitDisabled = React.useMemo(() => {
    if (editErrors && Object.keys(editErrors).length > 0) {
      return true;
    }

    return false;
  }, [editType, editErrors]);

  const handleEdit = (key, type, value, label) => {
    setEditType(type);
    setEditValue(value);
    setEditKey(key);
    setEditLabel(label);

    setModalVisible(true);
  };

  const ToInputCodiceFiscaleUppercase = (e) => {
    let idInput = e.target.id;
    if (idInput && idInput.includes("cod_fisc")) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };


  const validateEditForm = React.useCallback((values) => {
    const schema = user.data.type === 'supplier' ? getSupplierValidationSchema({ genders }) : getUserValidationSchema({ genders });
    const errors = {};

    console.log('validate values', values);

    try {
      schema.validateSyncAt(editKey, values);
    } catch (e) {
      errors[editKey] = e.message;
    }

    setEditErrors(errors);

    return errors;
  }, [user.data.type, editType, editKey]);

  const modalContent = React.useMemo(() => {

    const initValues = {};

    if (editType === 'date') {
      initValues[editKey] = moment(editValue).format('DD/MM/YYYY');
    } if (editType === 'media') {
      initValues[editKey] = '';
    } else {
      initValues[editKey] = editValue || '';
    }

    return (
      <div style={{ padding: 20 }}>
        <h6>
          Stai modificando:
          {' '}
          <span style={{ color: '#E4AC04' }}>{editLabel}</span>
        </h6>
        <Formik
          innerRef={formRef}
          validateOnMount
          initialValues={initValues}
          validate={validateEditForm}
          onSubmit={(values) => {
            if (editType === 'date') {
              updateUserData({
                [editKey]: moment(values[editKey], 'DD/MM/YYYY').format('DD-MM-YYYY'),
              }, () => setModalVisible(false));
            } else if (editType === 'media') {
              if (editKey === 'profile') {
                updateUserProfilePhoto(values[editKey], () => setModalVisible(false));
              } else {
                console.warn('Unsupported media type');
              }
              // updateUserData({
              //   [key]: moment(values[key], 'DD/MM/YYYY').format('DD-MM-YYYY'),
              // }, () => setModalVisible(false));
            } else {
              updateUserData(values, () => setModalVisible(false));
            }
          }}
        >
          {({
            values, errors, touched, handleChange, handleBlur, handleSubmit,
          }) => (
            <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">

              {(editType === 'text' || editType === 'email' || editType === 'phone') && (
                <div className="form-input-wrapper" style={{ padding: 0 }}>
                  <CustomInput
                    required
                    onChange={handleChange(editKey)}
                    onBlur={handleBlur(editKey)}
                    value={values[editKey]}
                    onInput={ToInputCodiceFiscaleUppercase}
                    id={editKey}
                    variant="outlined"
                  />
                </div>
              )}

              {editType === 'gender' && (
                <div
                  className="dropdown-input-wrapper"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <CustomInput
                        required
                        onChange={handleChange('gender')}
                        onBlur={handleBlur('gender')}
                        value={values.gender}
                        id="gender"
                        label="Sesso"
                        variant="outlined"
                        contentEditable={false}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onSelect={() => handleChange('gender')('Uomo')}>Uomo</Dropdown.Item>
                      <Dropdown.Item onSelect={() => handleChange('gender')('Donna')}>Donna</Dropdown.Item>
                      <Dropdown.Item onSelect={() => handleChange('gender')('Preferisco non specificare')}>Preferisco non specificare</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {editType === 'date' && (
                <div
                  className="datepicker-input-wrapper"
                  style={{
                    marginTop: 20, marginBottom: 20, marginLeft: 20, marginRight: 20,
                  }}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    dateFormat="dd/MM/Y"
                    showYearDropdown
                    selected={moment(values[editKey], 'DD/MM/YYYY')._d}
                    onChange={(date) => handleChange(editKey)(moment(date).format('DD/MM/YYYY'))}
                    customInput={(
                      <CustomInput
                        value={values[editKey]}
                        id="birthday"
                        label="Data di nascita"
                        variant="outlined"
                        contentEditable={false}
                      />
                    )}
                  />
                </div>
              )}

              {editType === 'media' && (
                <ImageUploader
                  withIcon
                  buttonText="Seleziona file"
                  imgExtension={['.jpg', '.jpeg', '.png']}
                  maxFileSize={5242880}
                  fileSizeError="File troppo grande"
                  fileTypeError="Estensione del file non supportata"
                  label="Estensione accettate: jpg, jpeg. Max. 5MB"
                  singleImage
                  withPreview
                  onChange={(file) => {
                    values[editKey] = file[0];
                    formRef.current.validateForm();
                  }}
                />
              )}

              <div className="errors-wrapper">
                {errors.email && touched.email && errors.email}
                {Object.keys(errors).map((e) => errors[e]).join('\n')}
              </div>

              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Button style={{ marginRight: 15 }} onClick={() => setModalVisible(false)} variant="red">Annulla</Button>
                <Button disabled={editFormSubmitDisabled} style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} variant="yellow">Continua</Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }, [editFormSubmitDisabled, editValue, editValue, editType, editKey]);

  if (user === undefined || user.data === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      <div>
        <div className="setting-item-title">Nome</div>
        <div className="setting-item-value">{user.data.first_name}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('first_name', 'text', user.data.first_name, 'Nome')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Cognome</div>
        <div className="setting-item-value">{user.data.last_name}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('last_name', 'text', user.data.last_name, 'Cognome')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Codice fiscale</div>
        <div className="setting-item-value">{user.data.cod_fisc}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('cod_fisc', 'text', user.data.cod_fisc, 'Codice fiscale')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Sesso</div>
        <div className="setting-item-value">{user.data.gender}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('gender', 'gender', user.data.gender, 'Nome')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Telefono fisso</div>
        <div className="setting-item-value">{user.data.phone_number}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('phone_number', 'phone', user.data.phone_number, 'Telefono fisso')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Telefono cellulare</div>
        <div className="setting-item-value">{user.data.mobile_number}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('mobile_number', 'phone', user.data.mobile_number, 'Telefono cellulare')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Data di nascita</div>
        <div className="setting-item-value">{moment(user.data.birthday).format('DD/MM/YYYY')}</div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('birthday', 'date', user.data.birthday, 'Data di nascita')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Email</div>
        <div className="setting-item-value">{user.data.email}</div>
        <div className="setting-item-edit">
          <p
            style={{ color: colors.grey.light }}
            onClick={() => {
              Swal.fire('Errore', 'Non è possibile modificare la email. Contattare il serivizio clienti', 'error');
            }}
          >
            Modifica
            <EditIcon style={{ color: colors.grey.light, fontSize: 16, marginLeft: 8 }}>
              filled
            </EditIcon>
          </p>
        </div>
      </div>

      <div>
        <div className="setting-item-title">Foto profilo</div>
        <div className="setting-item-value">
          <img alt="profile" src={getMediaUrl(user.data.media.profile)} />
        </div>
        <div className="setting-item-edit">
          <p onClick={() => handleEdit('profile', 'media', user.data.media.profile, 'Foto profilo')}>
            Modifica
            <EditIcon style={{ color: '#E4AC04', fontSize: 16, marginLeft: 8 }}>filled</EditIcon>
          </p>
        </div>
      </div>

      <Modal show={modalVisible} onHide={() => setModalVisible(false)} backdrop="static">
        {modalContent}
      </Modal>

    </div>
  );
};

export default AccountSettings;
