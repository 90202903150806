import React, { useState } from 'react';
import {useStripe, useElements, ExpressCheckoutElement} from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';

const StripeCheckout = ({history, sendReservationRequest, setLoading, price}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    //Submit Stripe request and handle errors
    const {error: submitError} = await elements.submit();
    if (submitError) {
      Swal.fire('Errore', submitError.message, 'error');
      return;
    }

    //Create the PaymentIntent and obtain clientSecret
    const stripePrice = parseInt(price * 100);
    const res = await fetch("https://api.myserencare.it/v1/createStripeIntent", {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ price: stripePrice })
      })

    const {client_secret: clientSecret} = await res.json();

    // Confirm the PaymentIntent using the details collected by the Express Checkout Element
    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/'
      },
      redirect: "if_required"
    });

    if (error) {
      Swal.fire('Errore', error.message, 'error');
    } else {
      sendReservationRequest(history);
      setLoading(true);
    }
  };

  return (
    <div id="checkout-page">
      <ExpressCheckoutElement id='ExpressCheckoutElementId' onConfirm={onConfirm} />
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

export default StripeCheckout;