import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';


import moment from 'moment/min/moment-with-locales';

import './styles.css';

import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getFullAddress, formatEventDatetime, translateStatus } from '../../libs/utils';

import SupplierMap from '../supplier-map';
import colors from '../../res/colors';

const ReservationRequestDetail = ({
  visible, onClose, search, services, history, reservationRequest, userCancelReservationRequest, onUpdate, user, userAcceptReservationRequest, userRefuseReservationRequest, downloadInvoice,
}) => {
  if (reservationRequest === undefined) return null;

  const {
    done_by: doneBy, service, start_date_time, end_date_time, address, notes, price_at_request_time: price, needy, supplier,
  } = reservationRequest;

  const startDate = moment(start_date_time);
  const endDate = moment(start_date_time);


  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5 style={{ textAlign: 'center' }}>Riepilogo richiesta di prestazione</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <div className="reservation-request-recap-wrapper">
          <div className="reservation-request-recap-item">
            <h6>Stato</h6>
            <p>{`${translateStatus(reservationRequest.status.value)}${reservationRequest.status.message && reservationRequest.status.message !== null ? `: ${reservationRequest.status.message}` : ''}`}</p>
          </div>

          <div className="reservation-request-recap-item">
            <h6>Servizio richiesto da</h6>
            <p>{`${doneBy.first_name} ${doneBy.last_name}`}</p>
          </div>

          {doneBy.type !== 'needy' && (
            <div className="reservation-request-recap-item">
              <h6>Da effettuare a</h6>
              <p>{`${needy.first_name} ${needy.last_name}`}</p>
            </div>
          )}

          <div className="reservation-request-recap-item">
            <h6>Fornitore</h6>
            <p>{supplier.full_name}</p>
          </div>

          <div className="reservation-request-recap-item">
            <h6>Servizio richiesto</h6>
            <p>{service.name}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Descrizione servizio richiesto</h6>
            <p>{service.description}</p>
          </div>
          <div className="reservation-request-recap-item">
            <h6>Quando</h6>
            <p>{formatEventDatetime(reservationRequest)}</p>
          </div>
          <div className="reservation-request-recap-item" style={{ marginBottom: 30 }}>
            <h6>Dove</h6>
            <p>{getFullAddress(address)}</p>
            {address.geometries && (
            <SupplierMap
              lat={address.geometries.latitude}
              lng={address.geometries.longitude}
            />
            )}
          </div>
          {notes && (
          <div className="reservation-request-recap-item">
            <h6>Note</h6>
            <p>{notes}</p>
          </div>
          )}
          <div className="reservation-request-recap-item" style={{ marginTop: 20 }}>
            <h6>Preventivo costi</h6>
            {`€ ${parseFloat(
              ((reservationRequest.price_at_request_time * reservationRequest.service.duration) / 6000) * reservationRequest.timeslots.length,
            ).toFixed(2)}`}
          </div>

          {reservationRequest.status.value !== 'refused' && (
            <div>
              {user.data.type === 'supplier' ? (
                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 }}>
                  <Button
                    style={{ marginRight: 15 }}
                    variant="red"
                    disabled={endDate.isBefore(moment)}
                    onClick={() => {
                      userRefuseReservationRequest(reservationRequest.id);
                      onUpdate();
                    }}
                  >
            Rifiuta richiesta
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    variant="green"
                    disabled={endDate.isBefore(moment)}
                    onClick={() => {
                      userAcceptReservationRequest(reservationRequest.id);
                      onUpdate();
                    }}
                  >
            Accetta richiesta
                  </Button>
                </div>
              ) : (
                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 }}>
                  <Button
                    variant="red"
                    disabled={endDate.isBefore(moment)}
                    onClick={() => {
                      Swal.fire({
                        title: 'Sei sicuro di voler cancellare questa richiesta?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: colors.secondary.normal,
                        cancelButtonColor: colors.red.normal,
                        confirmButtonText: 'Si, cancella!',
                        cancelButtonText: 'Annulla',
                      }).then((result) => {
                        if (result.value) {
                          userCancelReservationRequest(reservationRequest.id);
                          onUpdate();
                        }
                      });
                    }}
                  >
                    {endDate.isBefore(moment) ? 'Cancella richiesta' : 'Annulla richiesta'}
                  </Button>
                  {/* <Button
                    style={{ marginLeft: 15 }}
                    variant="yellow"
                    onClick={() => {
                      downloadInvoice(reservationRequest.id);
                    }}
                  >
                    Scarica ricevuta
                  </Button> */}
                </div>
              )}
            </div>
          )}


        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ReservationRequestDetail);
